/* eslint-disable no-useless-escape */
import React, { useState, useMemo } from "react";
import useEmailSend from "../../hooks/useEmailSend";
import ButtonContained from "../ButtonContained/ButtonContained";
import "./style.scss";

const regex =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const isValidEmail = (value) => regex.test(String(value).toLowerCase());

const successContent = (
  <div className="subscribe__success">Thank you for subscribing!</div>
);

const sendError = (
  <span className="subscribe__error">
    Something went wrong! Try again later.
  </span>
);

function getErrorMessage(email, valid) {
  if (!email) {
    return "This field is required";
  }
  if (!valid) {
    return "Incorrect email. ";
  }
  return "";
}

function SignInForConference({ title }) {
  let path = window.location.pathname;
  const screenWidth = window.screen.width;
  let mobile = screenWidth < 640;
  const [email, setEmail] = useState("");
  const [isEmailDirty, setIsEmailDirty] = useState(false);
  const [sendStatus, sendEmail] = useEmailSend();
  const isEmailValid = useMemo(() => isValidEmail(email), [email]);
  const errorMessage = useMemo(
    () => getErrorMessage(email, isEmailValid),
    [email, isEmailValid]
  );
  const onChange = (e) => {
    setEmail(e.target.value);
  };

  const onBlur = () => {
    if (!isEmailDirty) {
      setIsEmailDirty(true);
    }
  };

  const onFocus = () => {
    setIsEmailDirty(false);
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    if (!email) {
      setIsEmailDirty(true);
    }
    if (isEmailValid) {
      await sendEmail(email);
      setEmail("");
      setIsEmailDirty(false);
    }
  };

  const onSubmitClick = (e) => {
    e.stopPropagation();
  };

  const validationError = isEmailDirty && (
    <span className="subscribe__error">{errorMessage}</span>
  );

  const content = (
    <>
      <input
        id="email"
        name="email"
        className="subscribe__input"
        placeholder="Enter your email"
        autoComplete="disabled"
        value={email}
        maxLength={50}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
      />
      {mobile && (
        <span className="subscribe__error-field">
          {!(sendStatus === true) && validationError}
          {sendStatus === false && sendError}
        </span>
      )}
      <ButtonContained onClick={onSubmitClick} type="submit">
        Sign up
      </ButtonContained>
    </>
  );

  return (
    <form
      className="subscribe"
      autoComplete="disabled"
      action={path}
      method="POST"
      onSubmit={onSubmit}
    >
      <fieldset className="subscribe__fieldset">
        {title && <h2 className="subscribe__title">{title}</h2>}
        <div className="subscribe__wrap">
          {sendStatus === true ? successContent : content}
        </div>
        {!mobile && (
          <span className="subscribe__error-field">
            {!(sendStatus === true) && validationError}
            {sendStatus === false && sendError}
          </span>
        )}
      </fieldset>
    </form>
  );
}

export default SignInForConference;
