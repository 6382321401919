import React from "react";
// import liveStreamUrl from "./../../assets/images/sponsors-icons/lifestream.svg";
// import wifiUrl from "./../../assets/images/sponsors-icons/wifi.svg";
import coffeeStationUrlUrl from "./../../assets/images/sponsors-icons/coffe-station.svg";
import chillZoneUrl from "./../../assets/images/sponsors-icons/chillZone.svg";
import chargingPointUrl from "./../../assets/images/sponsors-icons/charging-point.svg";
import micStageUrl from "./../../assets/images/sponsors-icons/mic-stage.svg";
import loungeZoneUrl from "./../../assets/images/sponsors-icons/lounge-zone.svg";
import soldOutUrl from "./../../assets/images//sponsors-icons/sold_out.svg";
import exclusiveSmallUrl from "./../../assets/images/sponsors-icons/exclusive-line.svg";
import dimond from "./../../assets/images/sponsors-icons/dimond.svg";
import "./style.scss";

import clsx from "clsx";

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, A11y } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import { useIsMobile } from '../../hooks/useMediaQuery'

function ConferenceFacilities(props) {
  const facilities = [
    {
      title: "Charging point",
      img: chargingPointUrl,
      price: "$5 000",
      benefits: "Benefits",
      color: "red",
      sold: "",
      texts: [{ one: "3 branded charging stations around the venue" }],
      exclusiveUrl: exclusiveSmallUrl,
    },
    {
      title: "Coffee station",
      img: coffeeStationUrlUrl,
      price: "$7 500",
      benefits: "Benefits",
      color: "red",
      sold: "",
      texts: [
        { one: "Unlimited coffee for everyone" },
        { two: "Customised coffee cups" },
      ],
      exclusiveUrl: exclusiveSmallUrl,
    },
    {
      title: "2nd stage branding",
      img: micStageUrl,
      price: "$7 000",
      benefits: "Benefits",
      color: "red",
      sold: "",
      texts: [
        {
          one: (
            <span>
              The 2nd stage of <br /> the conference featured <br /> under your
              brand name
            </span>
          ),
        },
      ],
      exclusiveUrl: exclusiveSmallUrl,
    },
    {
      title: "Chill zone branding",
      img: chillZoneUrl,
      price: "$6 000",
      benefits: "Benefits",
      color: "red",
      sold: "",
      texts: [
        {
          one: (
            <span>
              One of the main conference <br /> facilities featured under <br />{" "}
              your brand name
            </span>
          ),
        },
      ],
      exclusiveUrl: exclusiveSmallUrl,
    },
    {
      title: "Your BRANDED lounge",
      img: loungeZoneUrl,
      price: "$7 500",
      benefits: "Benefits",
      color: "red",
      sold: "",
      texts: [
        {
          one: (
            <span>
              Host your own lounge
              <br /> during the conference
            </span>
          ),
        },
        { two: "Your custom setup prepared by us" },
      ],
      exclusiveUrl: exclusiveSmallUrl,
    },
  ];

  const facilitiesSlides = () => (
    // facilities.map((facility) => (
    <>
      <div className="caonference-first-box">
        <div className="inner-card">
          <div className="sold_out">
            <img src={soldOutUrl} alt="" />
          </div>
          <div className="element">
            <div className="inner-card__header red">
              <h3 className="card__title">Charging point</h3>
              <img src={chargingPointUrl} alt="chargingPointUrl" />
            </div>
            <div className="inner-card__exclusive-ofder">
              <img src={dimond} alt="dimond" />
              Exclusive offer
              <img src={dimond} alt="dimond" />
            </div>
            <div className="card__body">
              <h3 className="card__money">$5 000</h3>
              <p className="card__list-title">Benefits</p>
              <ul className="card__list">
                {[{ one: "3 branded charging stations around the venue" }]?.map(
                  (text) => (
                    <li className="card__elem">
                      {text?.one || text?.two || text?.three}
                    </li>
                  )
                )}
              </ul>
            </div>
          </div>
        </div>
        <div className="inner-card">
          <div className="sold_out">
            <img src={soldOutUrl} alt="" />
          </div>
          <div className="element">
            <div className="inner-card__header red">
              <h3 className="card__title">Coffee station</h3>
              <img src={coffeeStationUrlUrl} alt="chillZoneUrl" />
            </div>
            <div className="inner-card__exclusive-ofder">
              <img src={dimond} alt="dimond" />
              Exclusive offer
              <img src={dimond} alt="dimond" />
            </div>
            <div className="card__body">
              <h3 className="card__money">$7 500</h3>
              <p className="card__list-title">Benefits</p>
              <ul className="card__list">
                {[
                  { one: "Unlimited coffee for everyone" },
                  { two: "Customised coffee cups" },
                ]?.map((text) => (
                  <li className="card__elem">
                    {text?.one || text?.two || text?.three}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="caonference-second-box">
        <div className="inner-card">
          <div className="sold_out">
            <img src={soldOutUrl} alt="" />
          </div>
          <div className="element">
            <div className="inner-card__header red">
              <h3 className="card__title">2nd stage branding</h3>
              <img src={micStageUrl} alt="micStageUrl" />
            </div>
            <div className="inner-card__exclusive-ofder">
              <img src={dimond} alt="dimond" />
              Exclusive offer
              <img src={dimond} alt="dimond" />
            </div>
            <div className="card__body">
              <h3 className="card__money">$7 000</h3>
              <p className="card__list-title">Benefits</p>
              <ul className="card__list">
                {[
                  {
                    one: (
                      <span>
                        The 2nd stage of <br /> the conference featured <br />{" "}
                        under your brand name
                      </span>
                    ),
                  },
                ]?.map((text) => (
                  <li className="card__elem">
                    {text?.one || text?.two || text?.three}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div className="inner-card">
          <div className="sold_out">
            <img src={soldOutUrl} alt="" />
          </div>
          <div className="element">
            <div className="inner-card__header red">
              <h3 className="card__title">Chill zone branding</h3>
              <img src={chillZoneUrl} alt="chillZoneUrl" />
            </div>
            <div className="inner-card__exclusive-ofder">
              <img src={dimond} alt="dimond" />
              Exclusive offer
              <img src={dimond} alt="dimond" />
            </div>
            <div className="card__body">
              <h3 className="card__money">$6 000</h3>
              <p className="card__list-title">Benefits</p>
              <ul className="card__list">
                {[
                  {
                    one: (
                      <span>
                        One of the main conference <br /> facilities featured
                        under <br /> your brand name
                      </span>
                    ),
                  },
                ]?.map((text) => (
                  <li className="card__elem">
                    {text?.one || text?.two || text?.three}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div className="inner-card">
          <div className="sold_out">
            <img src={soldOutUrl} alt="" />
          </div>
          <div className="element">
            <div className="inner-card__header red">
              <h3 className="card__title">Your BRANDED lounge</h3>
              <img src={loungeZoneUrl} alt="chillZoneUrl" />
            </div>
            <div className="inner-card__exclusive-ofder">
              <img src={dimond} alt="dimond" />
              Exclusive offer
              <img src={dimond} alt="dimond" />
            </div>
            <div className="card__body">
              <h3 className="card__money">$7 500</h3>
              <p className="card__list-title">Benefits</p>
              <ul className="card__list">
                {[
                  {
                    one: (
                      <span>
                        Host your own lounge <br /> during the conference
                      </span>
                    ),
                  },
                  { two: "Your custom setup prepared by us" },
                ]?.map((text) => (
                  <li className="card__elem">
                    {text?.one || text?.two || text?.three}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
  // ));

  let small = useIsMobile();

  return (
    <div className="sponsors-block" {...props}>
      <h2 className="sponsors-block__title">Conference Facilities</h2>
      <p className="sponsors-block__subtitle">
        Exclusive offers for BH conference facility branding
      </p>

      {small ? (
        <Swiper
          navigation={false}
          pagination={{ clickable: true }}
          modules={[Pagination, A11y]}
          slidesPerView={1}
          spaceBetween={30}
          grabCursor={true}
          className="mySwiper"
        >
          {facilities.map((facility) => (
            <SwiperSlide>
              <div
                className={clsx("inner-card", facility?.sold)}
                key={facility?.title}
              >
                <div className="element">
                  <div className="sold_out">
                    <img src={soldOutUrl} alt="" />
                  </div>
                  <div className={clsx("inner-card__header", facility.color)}>
                    <h3 className="card__title">{facility.title}</h3>
                    <img src={facility.img} alt="" />
                  </div>
                  <div className="inner-card__exclusive-ofder">
                    <img src={dimond} alt="dimond" />
                    Exclusive offer
                    <img src={dimond} alt="dimond" />
                  </div>
                  <div className="card__body">
                    <h3 className="card__money">{facility.price}</h3>
                    <p className="card__list-title">{facility.benefits}</p>
                    <ul className="card__list">
                      {facility.texts?.map((text) => (
                        <li key={text.one} className="card__elem">
                          {text?.one || text?.two || text?.three || text?.four}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      ) : (
        <div className={`${small && "inner-cards rows-2"}`}>
          {facilitiesSlides()}
        </div>
      )}
    </div>
  );
}

export default ConferenceFacilities;
