import React, { useEffect, useState, useRef } from "react";
import "./style.scss";
import MultiToggle from "./../../components/MultiToggle/MultiToggle";
import EventSlider from "./EventSlider";
import MainBlock from "./MainBlock";
import ExhibitorBooth from "./ExhibitorBooth";
import DigitalSponsorship from "./DigitalSponsorship";
import ConferenceFacilities from "./ConferenceFacilities";
import separatorUrl from "./../../assets/images/sponsors-icons/separator.svg";
import sponsorsNoteUrl from "./../../assets/images/sponsors-icons/sponsors-note.svg";
import { useIsMobile } from "../../hooks/useMediaQuery";

function OfferForSponsors() {
  // const [windowWidth, setWindowWidth] = useState(0);
  // const [windowHeight, setWindowHeight] = useState(0);
  //
  // let resizeWindow = () => {
  //   setWindowWidth(window.innerWidth);
  //   setWindowHeight(window.innerHeight);
  // };
  //
  // useEffect(() => {
  //   resizeWindow();
  //   window.addEventListener("resize", resizeWindow);
  //   return () => window.removeEventListener("resize", resizeWindow);
  // }, []);
  const [showToggle, setShowToggle] = useState(true);
  const prevScrollYRef = useRef(window.pageYOffset);
  let small = useIsMobile();

  useEffect(() => {
    // because we are SPA its only way to add meta tag only for this page
    if (typeof document !== "undefined") {
      const meta = document.createElement("meta");
      meta.name = "robots";
      meta.content = "noindex,nofollow";
      document.getElementsByTagName("head")[0].appendChild(meta);
    }
  }, []);

  useEffect(() => {
    function handleScroll() {
      const currentScrollY = window.pageYOffset;
      if (currentScrollY < prevScrollYRef.current) {
        setShowToggle(true);
      } else {
        setShowToggle(false);
      }
      prevScrollYRef.current = currentScrollY;
    }
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <>
      <meta name="robots" content="noindex,nofollow" />
      <div className="page sponsors">
        <h1 className="sponsors__title">2023 Sponsorship tiers</h1>
        <p className="sponsors__subtitle general">
          Baltic Honeybadger offers a variety of sponsorship options depending
          on your business goal and budget{" "}
        </p>
        <div className="offers">
          <MultiToggle
            title="Select sponsor options"
            showToggle={showToggle}
            items={[
              { title: "Main Sponsors", id: "main" },
              { title: "Exhibitor Booths", id: "booth" },
              { title: "Digital Sponsorship", id: "digital" },
              { title: "Conference Facilities", id: "conference" },
              { title: "Events", id: "events" },
            ]}
          />

          <MainBlock id="main" />
          {!small && (
            <img
              src={separatorUrl}
              alt="separator"
              srcSet=""
              className="separator"
            />
          )}

          <ExhibitorBooth id="booth" />

          {!small && (
            <img
              src={separatorUrl}
              alt="separator"
              srcSet=""
              className="separator"
            />
          )}

          <DigitalSponsorship id="digital" />

          {!small && (
            <img
              src={separatorUrl}
              alt="separator"
              srcSet=""
              className="separator"
            />
          )}

          <ConferenceFacilities id="conference" />

          {!small && (
            <img
              src={separatorUrl}
              alt="separator"
              srcSet=""
              className="separator"
            />
          )}
          <EventSlider id="events" />
        </div>
        <div className="sponsors__note">
          <img src={sponsorsNoteUrl} alt="sponsors note" srcSet="" />
          <p className="sponsors__note__text">Prices without VAT</p>
        </div>
      </div>
    </>
  );
}

export default OfferForSponsors;
