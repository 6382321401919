import React from 'react'
import {Helmet} from "react-helmet";
// import gsap from 'gsap';
// import { ScrollTrigger } from "gsap/ScrollTrigger";

import Header from '../components/Header/Header';
import VideoSlider from '../components/VideoSlider/VideoSlider'
import Plan from '../components/Plan/Plan'
import SpeakersList from '../components/SpeakersList/SpeakersList'
// import AgendaList from '../components/AgendaList/AgendaList'
import PromoVideo from '../components/PromoVideo/PromoVideo';
import Partners from '../components/Partners/Partners';
// import ArticleSlider from '../components/ArticleSlider/ArticleSlider';
import SignInForConference from '../components/SignInForConference/SignInForConference';
import BlueBanner from '../components/BlueBanner/BlueBanner';
import NotPromoting from '../components/NotPromoting/NotPromoting';
import JoinCommunity from '../components/JoinCommunity/JoinCommunity';
import RigaBitcoinWeek from '../components/RigaBitcoinWeek/RigaBitcoinWeek';
import RigaHotels from '../components/RigaHotels/RigaHotels';



function Main() {

    return (
        <div className="page">
            <Helmet>
                <title>Baltic Honeybadger 2023 - European Bitcoin Event</title>
                <meta name="description" content="Join the 5th and the Biggest edition of Bitcoin only Conference 2023 held in Europe, Latvia." />
            </Helmet>
            <Header
                title="Baltic HoneyBadger 2023"
                subtitle="The most OG Bitcoin conference in the world"
                alt="Baltic HoneyBadger 2023"
            >
            </Header>
            <main>
                <section className="page-container">
                    <Plan/>
                    <SpeakersList/>
                    <NotPromoting
                        text='Not promoting crypto, various so called altcoins, tokens and other blockchain-based "snake oil"'/>
                </section>
                <section>
                    <PromoVideo />
                    <SignInForConference title="sign up for the newsletter"/>
                </section>
                <section className="page-container">
                    <RigaBitcoinWeek />
                    <BlueBanner/>
                    {/* <AgendaList/> */}
                </section>

                <section className="page-container">
                    <RigaHotels />
                    <Partners />
                    <JoinCommunity />
                    {/* <ArticleSlider/> */}
                </section>
                <VideoSlider />
            </main>
        </div>
    )
}

export default Main
