import React from 'react'
import './style.scss'


function NotPromoting( {text} ) {
  return (
    <div className="notPromoting">
        <p className="notPromoting__text">{text}</p>
    </div>
  )
}

export default NotPromoting
