import React from 'react'

import partners from './../../partners.json';
import './style.scss'

import Media from './../Media/Media'
import Sponsors from './../Sponsors/Sponsors'
import Exhibitors from '../Exhibitors/Exhibitors';

const IMAGE_URI = '/images/partners/';

function Partners() {

    let partnerItem = partners.map(partner => {
        const url = IMAGE_URI + partner.image;

        return (
            <a href={partner.url} target="_blank" key={partner.id} id={partner.id} className="partners__logo" rel="noreferrer">
                <img src={url} alt={partner.image} className="partner__image"/>
            </a>
        )
    })
    return (
        <section className="partners">
            <div className="partners__block">
                <h2 className="partners__title">Sponsors</h2>
                <Sponsors/>
            </div>
            <div className="partners__block">
                <h2 className="partners__title">Exhibitors</h2>
                <Exhibitors />
            </div>
            <div className="partners__block">
                <h2 className="partners__title">Partners</h2>
                <div className="partners__logos">
                    {partnerItem}
                </div>
            </div>
            <div className="partners__block">
                <h2 className="partners__title">Media</h2>
                <Media/>
            </div>
        </section>
    )
}

export default Partners
