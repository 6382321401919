import React from 'react'
import { Helmet } from 'react-helmet'
import SpeakersList from './../../components/SpeakersList/SpeakersList'

import './speakers.scss'
import { useIsMobile } from '../../hooks/useMediaQuery'

function Speakers() {
    let small = useIsMobile();
    return (
        <div className="page speakers">
            <Helmet>
                <title>Baltic Honeybadger 2023 - Speakers</title>
                <meta name="description" content="List of Speakers for the Baltic Honeybadger Conference." />
            </Helmet>
            {small ?
                <h2 className='speakers__title' title= "The loudest voices in Bitcoin">The loudest voices in Bitcoin</h2>
            :
                <h2 className='speakers__title' title= "The loudest voices in Bitcoin">The loudest voices in Bitcoin</h2> }
            <h1 className='speakers__subtitle'>List of speakers for the Baltic Honeybadger 2023</h1>
            <SpeakersList/>
        </div>
    )
}

export default Speakers
