import React from "react";
import { useIsMobile, useIsTablet } from '../../hooks/useMediaQuery'
import rigaHotels from "./riga-hotels.webp";
import rijaHotels from "./rija-hotels.svg";
import welltonHotels from "./wellton-hotels.svg";
import rixwellHotels from "./rixwell-hotels.svg";
import "../RigaBitcoinWeek/style.scss";

function RigaHotels({revertDirection= false}) {
  let tablet = useIsTablet();
  let mobile = useIsMobile();

  return (
    <div style={revertDirection ? {flexDirection:'row-reverse'} : {}}  className="rigaHotels">
      <div className="rigaHotels__content">
        {tablet && (
          <h2 className="rigaHotels__content__title" title="PARTNERING HOTELS IN RIGA">
            PARTNERING HOTELS IN RIGA
          </h2>
        )}
        <img alt="Hotels to stay in Riga" src={rigaHotels} className="rigaHotels__content__img" />
        {tablet && !mobile && (
          <div className="rigaHotels__content__logos">
            <a href="https://www.wellton.com/en" target="_blank" rel="noreferrer">
              <img alt="Wellton Hotels Riga" src={welltonHotels} className="rigaHotels__content__logos__img"/>
            </a>
            <a href="https://www.rijahotels.com/en" target="_blank" rel="noreferrer">
              <img alt="Rija Hotels Riga" src={rijaHotels} className="rigaHotels__content__logos__img"/>
            </a>
            <a href="https://www.rixwell.com/en" target="_blank" rel="noreferrer">
              <img alt="Rixwell Hotels Riga" src={rixwellHotels} className="rigaHotels__content__logos__img" />
            </a>
          </div>
        )}
        {tablet && (
          <p className="rigaHotels__content__text">
            Use BH2023 promo to receive special discounts for booking the accommodation through our partner hotels.
          </p>
        )}
        {mobile && (
          <div className="rigaHotels__content__logos">
            <a href="https://www.wellton.com/en" target="_blank" rel="noreferrer">
              <img alt="Wellton Hotels Riga" src={welltonHotels} className="rigaHotels__content__logos__img" />
            </a>
            <a href="https://www.rijahotels.com/en" target="_blank" rel="noreferrer">
              <img alt="Rija Hotels Riga" src={rijaHotels} className="rigaHotels__content__logos__img"/>
            </a>
            <a href="https://www.rixwell.com/en" target="_blank" rel="noreferrer">
              <img alt="Rixwell Hotels Riga" src={rixwellHotels} className="rigaHotels__content__logos__img"/>
            </a>
          </div>
        )}
      </div>
      {!tablet && (
        <div className="rigaHotels__content">
          <h2 className="rigaHotels__content__title" title="PARTNERING HOTELS IN RIGA">
            PARTNERING HOTELS IN RIGA
          </h2>
          <p className="rigaHotels__content__text">
            Use BH2023 promo to receive special discounts for booking the accommodation through our partner hotels.
          </p>
          <div className="rigaHotels__content__logos">
            <a href="https://www.wellton.com/en" target="_blank" rel="noreferrer">
              <img alt="Wellton Hotels Riga" src={welltonHotels} className="rigaHotels__content__logos__img"/>
            </a>
            <a href="https://www.rijahotels.com/en" target="_blank" rel="noreferrer">
              <img alt="Rija Hotels Riga" src={rijaHotels} className="rigaHotels__content__logos__img"/>
            </a>
            <a href="https://www.rixwell.com/en" target="_blank" rel="noreferrer">
              <img alt="Rixwell Hotels Riga" src={rixwellHotels} className="rigaHotels__content__logos__img"/>
            </a>
          </div>
        </div>
      )}
    </div>
  );
}

export default RigaHotels;
