import React, { useRef } from 'react'

import './termsConditions.scss'

function TermsConditions() {

    const appendix1 = useRef(null)
    const appendix2 = useRef(null)

    const toAppendix1 = () => appendix1.current.scrollIntoView()
    const toAppendix2 = () => appendix2.current.scrollIntoView()

    return (
        <div className='tc-wrapper'>
            <div className='tc-wrapper__header'>
                <h2 className='tc-wrapper__header__title' title="TERMS & CONDITIONS">TERMS & CONDITIONS</h2>
                <p>Baltic Honeybadger Conference 2023</p>
            </div>
            <div className='tc-wrapper__append'>
                <a onClick={toAppendix1} className='tc-wrapper__append__link'>Appendix 1: Access to the Conference</a>
                <a onClick={toAppendix2} className='tc-wrapper__append__link'>Appendix 2: House Rules</a>
                <p className='tc-wrapper__append__text'>These Terms apply to the purchase of Conference tickets by the Customer through all Official Sales Channels (as defined below).</p>
            </div>
            <div className='tc-wrapper__container'>
                <div className='tc-wrapper__container__block'>
                    <p className='tc-wrapper__container__title'>1. Definitions</p>
                    <p className='tc-wrapper__container__text'>
                        1.1 Agreement: the agreement between the Customer and the Official Sales Channel (as applicable) for the purchase of Conference tickets. This Agreement consists of these Terms and Conditions, the purchase confirmation and other provisions attached to or referred to in these Terms and Conditions, and/or the purchase confirmation and general terms and conditions of Tickettailor.<br />
                        1.2 Terms and Conditions: the present general terms and conditions, including the appendices.<br />
                        1.3 Consumer: the natural person who is not acting in the course of their commercial, business, craft, or professional activity.<br />
                        1.4 Customer, you, your: the consumer, legal entity, or de facto association that enters into the Agreement for the purchase of Conference tickets as provided herein.<br />
                        1.5 Conference: the 2023 edition of the 'Baltic Honeybadger' Bitcoin conference scheduled to take place at Fantadroms, G. Zemgala gatve 74a, Riga, Latvia, from September 2 to September 3, 2023.
                        1.6 Conference organizer: Gema Assets SIA, with company number 52103106731, the organizer and producer of the Conference.
                        1.7 Conference Products and/or Services: the products and/or services related to the Conference.<br />
                        1.8 Conference Visitor: The natural person who holds a valid registered Conference Ticket (as defined in this Agreement) and ultimately uses the Conference products and/or services in accordance with the Agreement.<br />
                        1.9 Force Majeure: Any of the following events the occurrence of which makes the performance of obligations (excluding payment obligations) under the Agreement and/or the Conference impossible, impracticable, or unsafe: an act of God (including without limitation avalanches), inevitable accident, fire, strike or other labor dispute, war or threat of war, an act of a public enemy, an act of terrorism or threats thereof, riot or civil commotion, national state of emergency, enactment, rule, order or act of any government or governmental instrumentality, failure of technical facilities, epidemic (including a health epidemic), interruption in, or substantial delay of necessary transportation services, or any event or condition beyond a party's reasonable control, as applicable. Inclement weather shall, in principle, not be deemed Force Majeure, provided, however that should such weather conditions be severe or threatening enough to render a declaration by the applicable public authorities that conditions threaten public safety, then such weather condition shall be deemed Force Majeure hereunder.<br />
                        1.10 Official Sales Channel: Conference tickets can only be purchased through the Conference Organizer or Ticket Partner. Conference tickets offered through the Conference Organizer are processed through Tickettailor, the Conference Organizer's service provider.<br />
                        1.11 Tickettailor: Zimma Ltd operates an online platform for ticket sales and access management and has been hired by the Conference as a supplier to make its platform available to the Conference Organizer, who can thus enter into an agreement with the Customer for the sale of Conference tickets as mentioned in this Agreement.<br />
                        1.12 Waiting List: If Conference tickets are sold out, the Conference Organizer reserves the right to open a Waiting List. By registering on the Waiting List, You authorize the Conference Organizer to contact You if tickets are available again.
                    </p>
                </div>
                <div className='tc-wrapper__container__block'>
                    <p className='tc-wrapper__container__title'>2. Scope of the Terms</p>
                    <p className='tc-wrapper__container__subtitle'>2.1 General Provisions</p>
                    <p className='tc-wrapper__container__text'>
                        2.1.1 The attachments are an integral part of these Terms and Conditions. <br />
                        By purchasing the ticket, you are deemed to have irrevocably agreed to these Terms of Service. You can always consult a recent version of the Terms and Conditions at www.baltichoneybadger.com; or upon request at the Conference customer service.<br />
                        2.1.2 If you are purchasing Conference tickets not only for yourself but also for others, we remind you that it is your sole responsibility to inform those people of these Terms and Conditions and any other terms and conditions that may apply to your purchase and to obtain their Agreement to those terms and conditions prior to your purchase.
                    </p>
                    <p className='tc-wrapper__container__subtitle'>2.2 Ticket Partners</p>
                    <p className='tc-wrapper__container__text'>
                        2.2.1 When you purchase Conference tickets through a Ticket Partner, the Ticket Partner, as the case may be, is your contracting party. You are entering into a direct agreement with them, and you will then be subject to their own terms and conditions of sale.<br />
                        It is the sole responsibility of such Ticket Partner to provide you with a copy of the applicable terms and conditions during the purchase process and no later than at the conclusion of the Agreement with you. <br />
                        The Conference Organizer in such a case is exempt from any contractual liability to you.
                    </p>
                </div>
                <div className='tc-wrapper__container__block'>
                    <p className='tc-wrapper__container__title'>3. Conference products and/or services: general provisions</p>
                    <p className='tc-wrapper__container__subtitle'>3.1 Official Sales Channels</p>
                    <p className='tc-wrapper__container__text'>
                        3.1.1 Conference tickets can only be purchased through the Official Sales Channels.<br />
                        3.1.2 Conference tickets obtained by any other means (through another sales channel or by somehow circumventing the procedure prescribed at www.baltichoneybadger.com) will not be considered valid by the Conference Organizer, and the Conference Organizer reserves the right to refuse the Conference ticket holder access to the Conference.
                    </p>
                    <p className='tc-wrapper__container__subtitle'>3.2 Conference ticket sales</p>
                    <p className='tc-wrapper__container__text'>
                        <b>General</b><br />
                        3.2.1 The Conference Organizer reserves the right to cancel and offer for sale again admission tickets to the Conference that do not comply with the provisions of this Article 3. In this case, the purchase price for the ticket(s) in question will be refunded to you (and only to you) after deduction of the Ticket Refund Fee and always in compliance with the cancellation provisions contained in this Agreement.<br />
                        3.2.2 The Conference Organizer reserves the right to cancel your purchase for technical reasons (e.g., if existing quotas are exceeded).<br />
                        3.2.3 Each entry ticket for the Conference will be delivered to you via an e-ticket
                    </p>
                    <p className='tc-wrapper__container__text'>
                        <b>Pre-Sale</b> <br />
                        3.2.4 The Conference Organizer will make available a pre-sale of Conference Tickets. If you subscribe to the conference newsletter before the deadline at www.baltichoneybadger.com, you will have the opportunity to purchase tickets before the official sale announcement.<br />
                        3.2.5 The Conference Organizer reserves the right to arrange ticket sales other than those listed above. If applicable, the special conditions for that particular sale will be published on www.baltichoneybadger.com so that the buyer can make an informed purchase.
                    </p>
                    <p className='tc-wrapper__container__text'>
                        <b>Admission to the Conference and identity verification</b><br />
                        3.2.6 The Conference Organizer reserves the right to check the identity of anyone entering the Conference grounds (or to have it checked by designated representatives). If one cannot identify oneself, the Conference visitor may be refused entry to the Conference. A valid identification document can be a valid ID card, passport, or driver's license. Copies of identity documents will not be accepted.<br />
                        3.2.7 The Conference Organizer reserves the right to check an interoperable vaccination/recovery certificate or proof of a negative COVID-19 test result on entry. If it cannot be provided, the Conference visitor may be refused entry to the Conference.<br />
                        3.2.8 The Conference is dedicated to providing a harassment-free conference experience for everyone, regardless of gender, gender identity, expression, sexual orientation, disability, physical appearance, body size, race, or religion. We do not tolerate harassment of conference participants in any form. Conference participants violating these rules may be sanctioned or expelled from the Conference at the conference organizers' discretion.<br />
                        3.2.9 A denied admission under Article does not entitle anyone to a refund.
                    </p>
                    <p className='tc-wrapper__container__subtitle'>3.3 Conference Entry Badge</p>
                    <p className='tc-wrapper__container__text'>
                        3.3.1 For each Conference entry ticket purchased, the Conference Organizer will provide you with a Conference Entry Badge, which must be worn during the whole event. A Conference Entry Badge can only grant one (1) person access to the Conference.<br />
                        3.3.2 The Conference Entry Badge must be picked up at the entrance to the Conference and can't be transferred to another person.
                    </p>
                    <p className='tc-wrapper__container__text'>
                    <b>Loss or theft of your Conference Entry Badge</b><br />
                    3.3.3 You are solely responsible for the loss or theft of your Conference Entry Badge upon receipt from the Conference Organizer (in accordance with the terms of these Terms and Conditions).
                    </p>
                </div>
                <div className='tc-wrapper__container__block'>
                    <p className='tc-wrapper__container__title'>4. Cancellations and refunds</p>
                    <p className='tc-wrapper__container__text'>
                        For purchases from the Conference Organizer (through the Tickettailor platform), cancellations and refunds can only be processed as provided here or on the Conference Organizer's website.<br />
                        or purchases from a Ticket Partner, cancellation and refund policies are governed exclusively by the Ticket Partner's terms and conditions. The Conference Organiser bears no liability whatsoever.
                    </p>
                </div>
                <div className='tc-wrapper__container__block'>
                    <p className='tc-wrapper__container__title'>5. Liability</p>
                    <p className='tc-wrapper__container__subtitle'> 5.1 Liability of the Customer and Conference Visitor</p>
                    <p className='tc-wrapper__container__text'>
                        <b>Refusal of access</b><br />
                        5.1.1 Anyone who fails to comply with these Terms and Conditions will be immediately denied entry or removed from the Conference grounds. No refunds will be made unless otherwise agreed here.<br />
                        5.1.2 The Conference Organizer reserves the right to refuse admission to multiple editions of the Conference or other events organized by the Conference Organizer and its affiliates, depending on the nature of non-compliance with these Terms and Conditions (for example, if you sell drugs).
                    </p>
                    <p className='tc-wrapper__container__text'>
                        <b>Prosecution</b><br />
                        The Conference Organizer reserves the right to prosecute any holder of counterfeit Conference Tickets and/or Conference Badges.
                    </p>
                    <p className='tc-wrapper__container__subtitle'> 5.2 Liability of the Conference Organizer</p>
                    <p className='tc-wrapper__container__text'>
                        <b>Direct damage</b><br />
                        5.2.1 The Conference Organizer shall only be liable for damages that are the direct and exclusive result of a breach by the Conference Organizer. Excluded from compensation are (i) consequential damages and (ii) intentional damages caused by deliberate recklessness on the part of the party suffering the damage.<br />
                        5.2.2 The Conference Organizer is not liable for damage, theft, or other accidents.
                    </p>
                    <p className='tc-wrapper__container__text'>
                        <b>Cancellation, relocation, or postponement of the Conference</b><br />
                        5.2.3 The Conference Conference may cancel, relocate or postpone the Conference for any reason, including Force Majeure. You have the right to compensation for your Ticket value in accordance with the applicable law. No further (direct or indirect) damages can be claimed.
                    </p>
                </div>
                <div className='tc-wrapper__container__block'>
                    <p className='tc-wrapper__container__title'>6. Conference tickets purchased from the Conference Organizer</p>
                    <p className='tc-wrapper__container__subtitle'> 6.1 Platform Tickettailor</p>
                    <p className='tc-wrapper__container__text'>
                    Conference tickets can be purchased directly from the Conference Organizer through the Tickettailor platform. A purchase can only be processed after the purchase price has been paid in full.
                    </p>
                    <p className='tc-wrapper__container__text'>
                    6.1.1 The Conference Organizer's Terms and Tickettailor's Terms and Privacy Policy are made available in full text and must be accepted before a purchase can be made.
                    </p>
                </div>
                <div className='tc-wrapper__container__block'>
                    <p className='tc-wrapper__container__title'>7. Applicable law and competent court</p>
                    <p className='tc-wrapper__container__text'>
                    7.1 These Conditions are governed exclusively by Latvian law, to the exclusion of conflict of law rules.<br />
                    7.2 All disputes shall be settled by the competent court in Riga, to the exclusion of all other courts.
                    </p>
                </div>
                <div className='tc-wrapper__container__block'>
                    <p className='tc-wrapper__container__title'>8. Other provisions</p>
                    <p className='tc-wrapper__container__text'>
                    8.1 If any provision of these Terms and Conditions, or any part thereof, is or is declared invalid or unenforceable, the remaining provisions of these Terms and Conditions or the remaining part of that provision shall remain in full force and effect, and such provision shall be automatically replaced (by operation of law) by a valid provision as close as possible to the invalid provision.
                    </p>
                </div>
            </div>

            <div ref={appendix1}  className='tc-wrapper__container__title__append'>
                <p className='tc-wrapper__container__title'>Appendix 1: Access to the Conference</p>
            </div>
            <div className='tc-wrapper__container'>
                <div className='tc-wrapper__container__block'>
                    <p className='tc-wrapper__container__title'>1. Opening hours of the Conference</p>
                    <p className='tc-wrapper__container__text'>
                    1.1 The opening hours of the Conference are as follows (subject to change):
                    </p>
                    <p className='tc-wrapper__container__text'>
                        Saturday, September 02/09/2023 <br />
                        10.00 - 18.00
                    </p>
                    <p className='tc-wrapper__container__text'>
                        Sunday, September 03/09/2023 <br />
                        10.00 - 18.00
                    </p>
                    <p className='tc-wrapper__container__text'>
                        1.2 The lineup of speakers determines the accessibility of the number of stages and the opening hours of each stage. Outside of a stage's opening hours, the stage area is not accessible to the public.<br />
                        1.3 With a validated Conference Badge, the Visitor, can check in up to one hour before the end of the Conference day; after that time, it is no longer possible to enter the Conference grounds.<br />
                        The Conference Organizer reserves the right to refuse Conference visitors who arrive later than one hour before the end of the Conference day access to the Conference site, even if the Conference visitor is in possession of a valid Conference entry ticket.
                    </p>
                </div>
                <div className='tc-wrapper__container__block'>
                    <p className='tc-wrapper__container__title'>2. Conference Agenda</p>
                    <p className='tc-wrapper__container__text'>
                    2.1 The Conference Organizer may find itself forced for compelling reasons (e.g., weather conditions, security, artist cancellations) to change or modify the Conference agenda and to close stages of the Conference. In this case, the Conference entry ticket remains valid, and no entry tickets will be refunded or exchanged.
                    </p>
                </div>
            </div>

            <div ref={appendix2} className='tc-wrapper__container__title__append'>
                <p className='tc-wrapper__container__title'>Appendix 2: House Rules</p>
            </div>
            <p className='tc-wrapper__container__text'>
                When visiting the Conference, the following house rules apply to all visitors (the "House Rules"). The House Rules are an integral part of the Terms and Conditions and are also posted at the entrance to the Conference.<br />
                The House Rules are always implemented in accordance with locally applicable laws, rules and regulations
            </p>

            <div className='tc-wrapper__container'>
                <div className='tc-wrapper__container__block'>
                    <p className='tc-wrapper__container__title'>Conference tickets and badges</p>
                    <p className='tc-wrapper__container__text'>
                        <span className='tc-wrapper__container__text__list'>Conference tickets and/or badges are neither refundable nor exchangeable.</span>
                        <span className='tc-wrapper__container__text__list'>In case of loss or theft of Conference tickets and/or Conference, they will not be replaced nor refunded. </span>
                        <span className='tc-wrapper__container__text__list'>The Organizer and/or the official ticket vendor reserve the right to verify the identity of the Conference visitor when checking the ticket and/or the Conference badge. If you are unable to present valid identification, security is authorized to refuse your entry to the Conference. The following are considered valid identification documents: identity card, passport, or driver's license. Copies will not be accepted. If you are caught with a false ID, this will be considered identity fraud, and you will be refused entry to the Conference. You will also be subject to criminal prosecution. </span>
                        <span className='tc-wrapper__container__text__list'>The Conference Organizer reserves the right to check an interoperable vaccination/recovery certificate or proof of a negative COVID-19 test result on entry. If it cannot be provided, the Conference visitor may be refused entry to the Conference. </span>
                        <span className='tc-wrapper__container__text__list'>Tickets and/or Badges Conference may not be resold without the express and written permission of the official ticket seller or Conference Organizer. Conference tickets that are resold without this express and written permission from the official ticket seller and/or Conference Organizer do not entitle the holder to admission to the Conference. The Organizer and/or the official ticket seller also reserves the right to cancel tickets for Conference once it is established that tickets for Conference have been offered for sale despite this prohibition. The costs resulting from this cancellation will be recovered from the buyer. </span>
                        <span className='tc-wrapper__container__text__list'>Changes in the lineup will not result in a full or even partial refund of the Conference ticket. </span>
                        <span className='tc-wrapper__container__text__list'>Forgery of the ticket or Badge for the Conference will be prosecuted.</span>
                        <span className='tc-wrapper__container__text__list'>Visitors to Conference may not enter areas to which their Badge does not grant access (e.g., the speaker zone, press zones, backstage, stages, etc.);</span>
                    </p>
                </div>
                <div className='tc-wrapper__container__block'>
                    <p className='tc-wrapper__container__title'>Access control</p>
                    <p className='tc-wrapper__container__text'>
                        <span className='tc-wrapper__container__text__list'>Access control is based on the applicable legislation aimed at detecting weapons and dangerous objects. If these items were present on the Conference grounds, they could have a negative impact on the smooth running of the event or jeopardize the safety of Conference-goers. </span>
                        <span className='tc-wrapper__container__text__list'>Entry to the Conference grounds may be denied, and visitors may be removed from the Conference grounds (without the right to a refund of the entry fee) if a visitor (the following list is not exhaustive): </span>
                        <span className='tc-wrapper__container__text__list'>Is under the influence of alcohol or drugs;</span>
                        <span className='tc-wrapper__container__text__list'>Is in possession of drugs or dealing drugs;</span>
                        <span className='tc-wrapper__container__text__list'>Had previously been denied entry to other events/meetings by the Organizer or other authorities;</span>
                        <span className='tc-wrapper__container__text__list'>Exhibits behavior or wears clothing that is clearly aimed at disturbing the peace, provoking or inciting violence, hatred, anger, xenophobia, racial hatred, etc.;</span>
                        <span className='tc-wrapper__container__text__list'>Endangering the lives of other visitors;</span>
                        <span className='tc-wrapper__container__text__list'>Is in possession of prohibited items;</span>
                        <span className='tc-wrapper__container__text__list'>Refuses to be searched by security personnel;</span>
                        <span className='tc-wrapper__container__text__list'>Refuses or fails to comply with the Terms and Conditions.</span>
                    </p>
                </div>

                <div className='tc-wrapper__container__block'>
                    <p className='tc-wrapper__container__title'>Security control</p>
                    <p className='tc-wrapper__container__text'>
                        <span className='tc-wrapper__container__text__list'>Entry to the Conference grounds implies acceptance of all prevention and control measures taken by the Conference Organizer or the security team, including the search of persons and bags.</span>
                        <span className='tc-wrapper__container__text__list'>Anyone wishing to enter the Conference grounds may be searched by security personnel prior to gaining access to the Conference grounds, always in accordance with local applicable laws and regulations.</span>
                        <span className='tc-wrapper__container__text__list'>Security personnel may ask people of the same sex to be searched and/or have their clothes and luggage searched to intercept prohibited items as set forth in the Conference House Rules (as incorporated herein). If prohibited items are found, security personnel have the right to confiscate them and deny the owner access to the Conference. No refunds will be given.</span>
                        <span className='tc-wrapper__container__text__list'>Everyone present on the Conference premises must at all times comply with the instructions and directives of the Conference Organizer or his authorized representatives on the Conference premises in order to ensure public order and safety during the Conference.</span>
                        <span className='tc-wrapper__container__text__list'>For safety reasons, the Conference Organizer reserves the right:</span>
                        <span className='tc-wrapper__container__text__dash'>To pause or stop the Conference;</span>
                        <span className='tc-wrapper__container__text__dash'>To prevent visitors from leaving the Conference grounds until further notice;</span>
                        <span className='tc-wrapper__container__text__dash'>To ask visitors to leave the Conference grounds;</span>
                        <span className='tc-wrapper__container__text__dash'>To deny visitors access to the Conference grounds, even if they are in possession of a valid admission ticket.</span>
                        <span className='tc-wrapper__container__text__list'>Conference visitors are not allowed to:</span>
                        <span className='tc-wrapper__container__text__dash'>To enter areas to which their Badges do not grant access (the speaker zone, press zones, backstage, stages, etc.);</span>
                        <span className='tc-wrapper__container__text__dash'>To climb on structures, fences, light bridges, benches, or other structures on the Conference grounds;</span>
                        <span className='tc-wrapper__container__text__dash'>To block entrances, exits, and emergency exits;</span>
                        <span className='tc-wrapper__container__text__dash'>To disguise themselves so that they are unrecognizable with the intention of disturbing the peace or endangering the safety of other visitors;</span>
                        <span className='tc-wrapper__container__text__dash'>To sell food, drink, or other products without the express permission of the Organizer;</span>
                        <span className='tc-wrapper__container__text__dash'>throw or shoot objects in solid, liquid, or gas form;</span>
                        <span className='tc-wrapper__container__text__dash'>To smoke in places where it is prohibited;</span>
                        <span className='tc-wrapper__container__text__dash'>To urinate in public;</span>
                        <span className='tc-wrapper__container__text__dash'>To take down, tear down, or destroy decorations;</span>
                        <span className='tc-wrapper__container__text__dash'>To steal decorations or other materials owned by Conference.</span>
                        <span className='tc-wrapper__container__text__dash'>To make fire;</span>
                        <span className='tc-wrapper__container__text__dash'>To wear texts, symbols, objects, clothing, and/or other (visible) external expressions that explicitly refer to a political, social movement, or group. Anything that indicates discrimination, incitement, or racism is strictly prohibited.</span>
                    </p>
                </div>
                <div className='tc-wrapper__container__block'>
                    <p className='tc-wrapper__container__title'>Prohibited items</p>
                    <p className='tc-wrapper__container__subtitle'>
                    The following items are prohibited on Conference grounds:
                    </p>
                    <p className='tc-wrapper__container__text'>
                        <span className='tc-wrapper__container__text__list'>Bring your own food and drink to Conference;</span>
                        <span className='tc-wrapper__container__text__list'>Drugs or other illegal mind-altering substances;</span>
                        <span className='tc-wrapper__container__text__list'>Deodorant or perfume in packages of more than 100 ml</span>
                        <span className='tc-wrapper__container__text__list'>Other objects (umbrellas, parasols, camera tripods, laser pens, etc.) that security personnel decide to pose a risk to other Conference visitors;</span>
                        <span className='tc-wrapper__container__text__list'>Projectiles or explosives in solid, liquid, or gas form;</span>
                        <span className='tc-wrapper__container__text__list'>Flammable products or materials and aerosols;</span>
                        <span className='tc-wrapper__container__text__list'>Pyrotechnic objects (fireworks, flares, etc.);</span>
                        <span className='tc-wrapper__container__text__list'>Other weapons or sharp, pointed objects (sticks, chains, knives, etc.);</span>
                        <span className='tc-wrapper__container__text__list'>Banners or objects with discriminatory and/or provocative texts or expressions that may be used to disturb the peace or that may endanger the safety of the crowd and/or cause injury or damage to persons and property;</span>
                        <span className='tc-wrapper__container__text__list'>Animals (except guide dogs);</span>
                        <span className='tc-wrapper__container__text__list'>Drones.</span>
                    </p>
                    <p className='tc-wrapper__container__subtitle'>
                        The following items are allowed on the Conference grounds:
                    </p>
                    <p className='tc-wrapper__container__text'>
                        <span className='tc-wrapper__container__text__list'>Guide dogs;</span>
                        <span className='tc-wrapper__container__text__list'>Disposable or pocket cameras;</span>
                        <span className='tc-wrapper__container__text__list'>Retractable umbrellas without pin (no parasols);</span>
                        <span className='tc-wrapper__container__text__list'>Refreshers/atomizers, small backpacks, or other bags.</span>
                        <span className='tc-wrapper__container__text__list'>Selfie sticks that are less than 1 m in length when extended and are intended for photography only.</span>
                    </p>
                </div>
                <div className='tc-wrapper__container__block'>
                    <p className='tc-wrapper__container__title'>The conference grounds. Damage to materials</p>
                    <p className='tc-wrapper__container__text'>'Fantadroms', the location of the Conference, is an event venue, and all visitors are requested to do their part to keep the surroundings as clean as possible. On the Conference site, it is clearly indicated where you can leave your waste.
                    </p>
                    <p className='tc-wrapper__container__text'>The Conference Organizer puts in a lot of effort and manpower to make Conference a magical world. We ask every attendee to show due respect! Tearing off, breaking, or any other form of destruction to the Conference Organizer's materials, including theft, will result in removal from the Conference. Entry will be denied for the remainder of the Conference and may be denied for subsequent years.
                    </p>
                </div>
                <div className='tc-wrapper__container__block'>
                    <p className='tc-wrapper__container__title'>Merchandise</p>
                    <p className='tc-wrapper__container__text'>Conference official merchandise is only available for purchase at the Conference.
                    </p>
                </div>
                <div className='tc-wrapper__container__block'>
                    <p className='tc-wrapper__container__title'>Photographs and video recordings. Camera surveillance</p>
                    <p className='tc-wrapper__container__text'>Photo and video recordings will be made during the Conference. There is a good chance that you will be filmed. By entering the Conference grounds, you agree to the processing of these images.
                    </p>
                    <p className='tc-wrapper__container__text'>Camera surveillance at Conference is carried out in accordance with local legislation. These recordings will only be used for security purposes during the Conference and will not be passed on to third parties except to the competent authority upon receipt of a formal request.
                    </p>
                </div>
                <div className='tc-wrapper__container__block'>
                    <p className='tc-wrapper__container__title'>Promotion</p>
                    <p className='tc-wrapper__container__text'>It is forbidden to distribute flyers or hang posters during the Conference!
                    </p>
                </div>
                <div className='tc-wrapper__container__block'>
                    <p className='tc-wrapper__container__title'>Safety in front of and around the stage. Loss or damage.</p>
                    <p className='tc-wrapper__container__text'>The artists and organizers of the Conference cannot be held responsible for damage, loss or theft, or any other accident.
                    </p>
                </div>
                <div className='tc-wrapper__container__block'>
                    <p className='tc-wrapper__container__title'>Overview</p>
                    <p className='tc-wrapper__container__text'>
                    The Conference Organizer may cancel or postpone Conference in the event of Force Majeure or if the Event Organizer has another demonstrable compelling reason to cancel or postpone the Conference. Force Majeure shall include, but not be limited to: terrorist threats, terrorist attacks, adverse weather conditions, strikes, fire, war, government intervention, pandemics, illness of artists, failure and/or delay of technical facilities, failure and/or delay of means of transport, etc.<br />
                    The Conference Organizer may be forced to close venues due to weather conditions or other force majeure events. This will not result in a partial or full refund.
                    </p>
                </div>

            </div>
        </div>
    )
}

export default TermsConditions
