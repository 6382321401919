import React from 'react'
import './style.scss'

const MAP_URL = 'https://www.google.com/maps/place/Gustava+Zemgala+gat.+74a,+Vidzemes+priek%C5%A1pils%C4%93ta,+R%C4%ABga,+LV-1039/@56.9762228,24.1646957,17z/data=!3m1!4b1!4m6!3m5!1s0x46eece57042f4d61:0x3336c5cb50c713a7!8m2!3d56.9762199!4d24.1668844!16s%2Fg%2F11h46395by?authuser=0'

function ConferenceLocation() {
    return (
        <div className="conference-location">
            <h3 className="conference-location__title" title="Conference location">Conference location</h3>
            <a className="conference-location__subtitle" href={MAP_URL} target="_blank" rel="noreferrer noopener" >Gustava Zemgala gat. 74a, Vidzemes priekšpilsēta, Rīga,
                LV-1039, Latvia</a>
            <p className="conference-location__description">Fantadroms is an inspirational, creative and innovative
                venue, hosting Baltic Honeybadger for a 2nd time.
                Located in Jaunā Teika, an innovational oasis of Riga, only 15 minutes drives from the city centre. </p>
        </div>
    )
}

export default ConferenceLocation
