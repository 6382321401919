import React, { useLayoutEffect, useRef, useEffect, useState } from "react";
import clsx from "clsx";
import "./style.scss";
import { useIsMobile } from "../../hooks/useMediaQuery";

const useHash = () => window.location.hash.replace("#", "");

const useBreakpoints = () => {
  let small = useIsMobile();

  return {
    small,
  };
};

const Select = ({ options, onItemClick, title }) => {
  const [showSelect, setShowSelect] = useState(false);
  const [option, setOption] = useState(title);

  const handleOptionClick = () => {
    setShowSelect(!showSelect);
  };

  const onItemClickHandler = (item, e) => {
    if (onItemClick) {
      onItemClick(item, e);
    }

    setOption(item.title);

    setTimeout(() => {
      setShowSelect(false);
    }, 100);
  };

  return (
    <div id="month" className="select">
      <div
        id="show-options"
        onClick={handleOptionClick}
        className="select-option"
      >
        {option}
      </div>
      <div
        id="options-list"
        className={clsx("select-options", {
          visible: showSelect,
        })}
      >
        {options.map((item) => (
          <div
            key={item.id}
            className="select-item"
            onClick={(e) => onItemClickHandler(item, e)}
          >
            {item.title}
          </div>
        ))}
      </div>
    </div>
  );
};

const scrollToAnchor = (activeId) => {
  const $$node = document.getElementById(activeId);
  const $$nav = document.querySelector("div.nav");
  const $$data = document.querySelector("#data");

  const gap = 20;
  const offset = $$nav?.clientHeight + $$data?.clientHeight + gap || gap;

  if ($$node) {
    window.scrollTo({
      top: $$node.offsetTop - offset,
      behavior: "smooth", // smooth scroll
    });

    console.log("scrollTo", activeId, $$node.offsetTop);

    if (window.location.hash.indexOf(activeId) <= 0) {
      window.history.pushState({ id: activeId }, null, `#${activeId}`);
    }
  }
};

const MultiToggle = ({ items = [], title, showToggle }) => {
  const hashId = useHash();
  const toggleRef = useRef(null);
  const [activeId, setActiveId] = useState(hashId);
  const [toggleTop, setToggleTop] = useState(false);
  const { small } = useBreakpoints();

  useEffect(() => {
    const handleScroll = () => {
      const element = toggleRef.current;
      if (element) {
        const { top } = element.getBoundingClientRect();
        if (top <= 0) {
          setToggleTop(true);
        }
      } else {
        setToggleTop(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleItem = (item, e) => {
    e?.preventDefault();

    setActiveId(item.id);

    scrollToAnchor(item.id);
  };

  useLayoutEffect(() => {
    setTimeout(() => {
      if (hashId) {
        scrollToAnchor(hashId);
      }
    });
  }, [hashId]);

  if (small) {
    return <Select options={items} onItemClick={handleItem} title={title} />;
  }
  
  return (
    <div
      className={clsx("multi-toggle-box fixed", {
        stickyToggle: !showToggle,
        topDistance: toggleTop,
      })}
      ref={toggleRef}
    >
      <div className="multi-toggle-container fixed" id="data">
        {items.map((item) => (
          <a
            key={item.id}
            href={`#${item.id}`}
            onClick={(e) => handleItem(item, e)}
            className={clsx("multi-toggle-item", {
              active: item.id === activeId,
            })}
          >
            {item.title}
          </a>
        ))}
      </div>
    </div>
  );
};

export default MultiToggle;
