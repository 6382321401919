import React, { useState, Fragment, useEffect, useRef } from "react";
import clsx from "clsx";
import { Link } from "react-router-dom";
import closeMenuUrl from "./../../assets/images/close.svg";
import gsap from "gsap";
import { useMediaQuery } from "../../hooks/useMediaQuery";
import ButtonContained from "../ButtonContained/ButtonContained";

import "./style.scss";

const links = [
  {
    title: "Speakers",
    to: "/speakers",
  },
  {
    title: "Agenda",
    to: "/agenda",
  },
  {
    title: "Riga Bitcoin Week",
    to: "/riga-bitcoin-week",
  },
  {
    title: "Location",
    to: "/location",
  },
];

// export const BuyTicketsButton = ({ onClick }) => {
//   return (
//     <Link
//       onClick={onClick}
//       to="/buy-tickets"
//       alt="Buy Tickets"
//     >
//       Buy Tickets
//     </Link>
//   );
// };

function Navigation() {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [showHeader, setShowHeader] = useState(true);
  const prevScrollYRef = useRef(window.pageYOffset);
  let isTablet = useMediaQuery(`(max-width: 1024px)`);

  const onShowMenu = () => {
    if (!isMenuOpen) {
      gsap.to("#menu", { duration: 0.1, left: 0 });
      setMenuOpen(!isMenuOpen);
    } else if (isMenuOpen) {
      gsap.to("#menu", { duration: 0.1, left: "-100%" });
      setMenuOpen(!isMenuOpen);
    }
  };

  const onMenuClose = () => {
    gsap.to("#menu", { duration: 0.1, left: "-100%" });
    setMenuOpen(false);
  };

  useEffect(() => {
    function handleScroll() {
      const currentScrollY = window.pageYOffset;
      if (currentScrollY < prevScrollYRef.current) {
        setShowHeader(true);
      } else {
        setShowHeader(false);
      }
      prevScrollYRef.current = currentScrollY;
    }
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <Fragment>
      <div
        className={clsx("nav", {
          stickyNav: showHeader,
        })}
      >
        <div className="nav__container page-container">
          <div className="nav__left">
            <Link to="/" className="nav__logo" alt="Main page">
              {isTablet ? (
                <span className="nav__logo__title">Baltic Honeybadger</span>
              ) : (
                <span className="nav__logo-image" title="Baltic Honeybadger" />
              )}
            </Link>
            <div className="nav__date">
              September 2 - 3, 2023 <br /> Riga, Latvia
            </div>
          </div>

          <div className="header__menu menu nav__right">
            <div onClick={onShowMenu} className="menu__icon">
              <span />
            </div>
            <nav onClick={onMenuClose} id="menu" className="menu__body">
              <div className="menu__body__header">
                {isMenuOpen && (
                  <h1 className="menu__body__title">Baltic Honeybadger</h1>
                )}
                <img
                  src={closeMenuUrl}
                  alt="close menu button"
                  className="menu__close"
                />
              </div>
              <ul className="menu__list">
                {links.map((link) => (
                  <Link
                    key={link.to}
                    onClick={onMenuClose}
                    to={link.to}
                    className="nav__link nav__link_button"
                    alt={link.title}
                  >
                    {link.title}
                  </Link>
                ))}
                {/* <ButtonContained onClick={onMenuClose}>
                  <BuyTicketsButton />
                </ButtonContained> */}
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Navigation;
