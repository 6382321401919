import loadAsync from './loadAsync'

function loadRecaptcha(key) {
    return loadAsync(`https://www.google.com/recaptcha/enterprise.js?render=${key}`)
}

const getToken = (key) => new Promise((resolve) => {
    if (typeof window !== 'undefined' && window.grecaptcha) {
        if (window.grecaptcha.enterprise) {
            window.grecaptcha.enterprise.ready(function () {
                window.grecaptcha.enterprise.execute(key, { action: 'login' }).then(function (token) {
                    resolve(token)
                });
            });
        } else {
            window.grecaptcha.ready(function () {
                window.grecaptcha.execute(key, { action: 'login' }).then(function (token) {
                    resolve(token)
                });
            });
        }
    } else {
        resolve(null)
    }
})

export const getReCaptchaToken = async (key) => {
    await loadRecaptcha(key)

    return await getToken(key)
}


export default loadRecaptcha
