import React from "react";
import { Helmet } from "react-helmet";
import Days from "./Days.jsx";

import btcpayserverday from "./btcpayserverday.svg";
import lights from "../../assets/images/honeybadger_lightning.webp";
import nostr from "./nostr.svg";
import nostrsm from "./nostrsm.svg";
import mining from "./mining.webp";

import "./bitcoinWeek.scss";

const noobDayDescription = (
  <ul>
    <li>
      <span className="semibold">
        Bitcoin empowers Africa and Africans empower Bitcoin
      </span>{" "}
      by{" "}
      <a href="https://twitter.com/AnitaPosch" target="_blank" rel="noreferrer">
        Anita Posch
      </a>
    </li>
    <li>
      <span className="semibold">Practical Bitcoin privacy and security</span>{" "}
      by{" "}
      <a href="https://twitter.com/BitcoinQ_A" target="_blank" rel="noreferrer">
        Bitcoin Q+A
      </a>
    </li>
    <li>
      <span className="semibold">Bitcoin Psychopaths and Sociopaths</span> by{" "}
      <a href="https://twitter.com/CoinsureNZ" target="_blank" rel="noreferrer">
        Coinsure
      </a>
    </li>
    <li>
      <span className="semibold">How to get bitcoin</span> by{" "}
      <a
        href="https://twitter.com/EthanBitcoin"
        target="_blank"
        rel="noreferrer"
      >
        Ethan
      </a>
    </li>
    <li>
      <span className="semibold">Buying bitcoin P2P</span> by{" "}
      <a
        href="https://twitter.com/firstworldpeace"
        target="_blank"
        rel="noreferrer"
      >
        Tony Nakamoto
      </a>
    </li>
    <li>
      <span className="semibold">Your brain on Bitcoin</span> by{" "}
      <a
        href="https://twitter.com/knutsvanholm"
        target="_blank"
        rel="noreferrer"
      >
        Knut Svanholm
      </a>
    </li>
    <li>
      <span className="semibold">Bitcoin as a Polymath creature</span> by{" "}
      <a href="https://twitter.com/mir_btc" target="_blank" rel="noreferrer">
        Mir Liponi
      </a>
    </li>
    <li>
      <span className="semibold">
        Bitcoin history, cypherpunks and the road ahead
      </span>{" "}
      by{" "}
      <a
        href="https://twitter.com/BTCSchellingPt"
        target="_blank"
        rel="noreferrer"
      >
        WizardofAus
      </a>
    </li>
    <li>
      <span className="semibold">Bitcoin is private property</span> by{" "}
      <a
        href="https://twitter.com/Yippiebeans"
        target="_blank"
        rel="noreferrer"
      >
        Kai
      </a>
    </li>
    <li>
      <span className="semibold">Bitcoin: Discovery or Invention?</span> by{" "}
      <a href="https://twitter.com/HodlersWay" target="_blank" rel="noreferrer">
        Izzy
      </a>
    </li>
  </ul>
);

const pitchDescription = (
  <div>
    <p className="mb-32">
      Submit your application to participate in the Pitch Competition.
    </p>
    <p>Requirements:</p>
    <ul>
      <li>Bitcoin only</li>
      <li>Pre-seed, Seed, Series A stage</li>
      <li>Participation in person only</li>
    </ul>
  </div>
);

const direction = "https://goo.gl/maps/CQZXtbJJpmmoHFVz9";

const BitcoinWeek = () => (
  <div className="bitcoin-week">
    <Helmet>
      <title>Baltic Honeybadger 2023 - Riga Bitcoin Week</title>
      <meta
        name="description"
        content="Riga Bitcoin Week schedule, a week of high-quality educational content delivered by the Top Bitcoin Speakers."
      />
    </Helmet>
    <div className="bitcoin-week__container">
      <h1 className="bitcoin-week__title" title="Riga Bitcoin week 2023">
        Riga Bitcoin Week 2023
      </h1>
      <p className="bitcoin-week__subtitle">
        A week of high-quality educational content delivered by the top industry
        players
      </p>
    </div>

    <Days
      className="noob-day"
      title="August 31st"
      headTitle="NOOB day"
      ticket="Free entrance"
      infoTitle="Introduction to Bitcoin"
      infoDescription={noobDayDescription}
      color="blue"
      location={
        <a
          href="https://goo.gl/maps/hNGCPToHytqUXDZW7"
          target="_blank"
          rel="noreferrer"
        >
          Tavi draugi
        </a>
      }
      clock="11:00 - 17:00"
      subtitle="The opening of the Riga Bitcoin Week."
    />

    <Days
      headTitle="spanish meetup"
      ticket="Free entrance"
      infoTitle="Spanish Bitcoin community meetup"
      infoDescription="We are welcoming the Spanish-speaking Bitcoin community in Riga. Attend the event if you speak Spanish and want to meet local community representatives."
      color="red"
      location={
        <a
          href="https://maps.app.goo.gl/R1UQN21oNndMq4ZEA?g_st=ic"
          target="_blank"
          rel="noreferrer"
        >
          Basement bar
        </a>
      }
      clock="18:00 - 22:00"
    />

    <Days
      headTitle="RaspiBlitz Meetup"
      ticket="Free entrance"
      infoTitle="RaspiBlitz community meetup"
      infoDescription="Whether you're a dedicated RaspiBlitz full node runner or simply someone who appreciates the power of Open-Source collaboration, join the meetup for talks and discussions of the project's current issues and future developments. Hosted by: rootzoll & openoms"
      color="yellow"
      location={
        <a
          href="https://goo.gl/maps/3UDGjzW3BZkLik669"
          target="_blank"
          rel="noreferrer"
        >
          OraculeTang Space
        </a>
      }
      clock="18:00 - 22:00"
    />

    {/* <Days
            className="pitch-competition"
            headTitle="pitch COMPETITION"
            headSubtitle="by Fulgur Ventures"
            ticket="BH2023 ticket holders"
            infoTitle="Are you building a Bitcoin startup?"
            infoDescription={pitchDescription}
            location={<a href='https://goo.gl/maps/X3ANgcChs4SqJPzUA' target='_blank' rel="noreferrer">Gravity Team office</a>}
            color="red"
            clock="18:00 - 22:00"
            buttons={[{ title: "Submit application", path: "https://docs.google.com/forms/d/e/1FAIpQLSeX69C1OIkPoz2TxT8ZmTQ29Nv3tsaTPuKwAO47TiV4hs6Q0w/viewform?usp=sf_link" }]}
        /> */}
    <Days
      className="layer"
      title="September 1st"
      headTitle="LAYER 2 DAY"
      ticket="BH2023 ticket holders"
      infoTitle="Presenting the latest Layer 2 developments"
      infoDescription={
        <>
          <a
            href="https://twitter.com/lightning"
            target="_blank"
            rel="noreferrer"
          >
            Lightning Labs
          </a>
          ,{" "}
          <a
            href="https://twitter.com/Blockstream"
            target="_blank"
            rel="noreferrer"
          >
            Blockstream
          </a>
          ,{" "}
          <a
            href="https://twitter.com/Breez_Tech"
            target="_blank"
            rel="noreferrer"
          >
            Breez
          </a>
          ,{" "}
          <a
            href="https://twitter.com/Synonym_to"
            target="_blank"
            rel="noreferrer"
          >
            Synonym
          </a>
          ,{" "}
          <a href="https://twitter.com/brqgoo" target="_blank" rel="noreferrer">
            Ark
          </a>
          ,{" "}
          <a
            href="https://twitter.com/voltage_cloud"
            target="_blank"
            rel="noreferrer"
          >
            Voltage
          </a>
          ,{" "}
          <a href="https://twitter.com/lnp_bp" target="_blank" rel="noreferrer">
            LNP/BP Standards Association
          </a>
          ,{" "}
          <a
            href="https://twitter.com/GaloyMoney"
            target="_blank"
            rel="noreferrer"
          >
            Galoy
          </a>
          ,{" "}
          <a href="https://twitter.com/lnbits" target="_blank" rel="noreferrer">
            LNbits
          </a>
          ,{" "}
          <a
            href="https://twitter.com/Boltzhq"
            target="_blank"
            rel="noreferrer"
          >
            Boltz
          </a>
          ,{" "}
          <a
            href="https://twitter.com/CashuBTC"
            target="_blank"
            rel="noreferrer"
          >
            Cashu
          </a>
          .
        </>
      }
      color="yellow"
      location={
        <a
          href="https://goo.gl/maps/hNGCPToHytqUXDZW7"
          target="_blank"
          rel="noreferrer"
        >
          Tavi draugi
        </a>
      }
      clock="11:00 - 17:00"
      subtitle="Latest L2 developments presented by the top industry companies."
      imageAlt="lights"
      imageSrc={lights}
      imageClass="lights"
    />
    <Days
      className="anita"
      headTitle="Anita Posch Meetup"
      ticket="Free entrance"
      infoTitle="For everyone interested in Bitcoin as a freedom tool "
      infoDescription="The meet-up will focus on Bitcoin as a freedom tool, Human Rights, and building communities in Africa - inviting people to listen to Anita's experiences in education (with a focus on Africa) and share theirs."
      color="blue"
      location={
        <a
          href="https://maps.app.goo.gl/totJzgSPg5y5FSpG7?g_st=ic"
          target="_blank"
          rel="noreferrer"
        >
          Zefirs
        </a>
      }
      clock="18:00 - 19:30"
    />
    <Days
      className="twentyone"
      headTitle="Twentyone party"
      ticket="Free entrance"
      infoTitle="TwentyOne Nights Welcome Party "
      location={
        <a
          href="https://maps.app.goo.gl/R1UQN21oNndMq4ZEA?g_st=ic"
          target="_blank"
          rel="noreferrer"
        >
          Basement bar
        </a>
      }
      background="all-black"
      clock="20:00 - 02:00"
      infoDescription={
        <>
          <a href="https://twentyone.world" target="_blank" rel="noreferrer">
            Twentyone.world
          </a>{" "}
          is a network of open communities that provide localized, high-quality,
          Bitcoin-only content, where each community provides a space for
          bitcoiners in their respective countries to connect, collaborate, and
          exchange ideas. Attend the event to meet the TwentyOne global
          community, enjoy drinks with Bitcoiners, listen to good music, and pay
          with Bitcoin!{" "}
        </>
      }
    />

    <Days
      className="conferance1"
      title="September 2nd"
      headTitle="Conference DAY 1"
      location={
        <a
          href="https://goo.gl/maps/ZjxfrbeP2JhHDbAi6"
          target="_blank"
          rel="noreferrer"
        >
          Fantadroms
        </a>
      }
      clock="10:00 - 18:00"
      color="black"
      imageAlt="Baltic Honeybadger"
      imageClass="baltic-mining"
      imageSrc={mining}
      buttons={[
        // { id: 1, title: "Buy tickets", path: "/buy-tickets" },
        { id: 2, title: "GET Directions ", path: direction },
      ]}
      infoTitle="Baltic Honeybadger"
      infoDescription="Presentations, panels, and fireside chats about the most crucial and controversial topics in Bitcoin, a day full of content on 2 stages, workshops, and demos!"
    />
    <Days
      className="mainafterparty"
      headTitle="MAIN AFTERPARTY"
      ticket="BH2023 ticket holders"
      infoTitle="Official afterparty"
      location={
        <a
          href="https://goo.gl/maps/ZjxfrbeP2JhHDbAi6"
          target="_blank"
          rel="noreferrer"
        >
          Fantadroms
        </a>
      }
      background="all-black"
      clock="18:00 - 24:00"
      infoDescription="An afterparty for all Baltic Honeybadger attendees, where you can network, meet the speakers in an informal atmosphere and enjoy the music delivered by bitcoiners and famous BH DJ - All-viss!"
    />

    <Days
      className="conferance2"
      title="September 3rd"
      headTitle="Conference DAY 2"
      location={
        <a
          href="https://goo.gl/maps/ZjxfrbeP2JhHDbAi6"
          target="_blank"
          rel="noreferrer"
        >
          Fantadroms
        </a>
      }
      clock="10:00 - 18:00"
      color="black"
      imageAlt="Baltic Honeybadger"
      imageClass="baltic-mining"
      imageSrc={mining}
      buttons={[
        // { id: 1, title: "Buy tickets", path: "/buy-tickets" },
        { id: 2, title: "GET Directions ", path: direction },
      ]}
      infoTitle="Baltic Honeybadger"
      infoDescription="Presentations, panels, and fireside chats about the most crucial and controversial topics in Bitcoin, a day full of content on 2 stages, workshops, and demos!"
    />
    <Days
      className="nostrparty"
      headTitle="NOSTR PARTY"
      ticket="BH2023 ticket holders"
      infoTitle="What is a Nostr party?"
      infoDescription="Nostr is a simple, open protocol that enables global, decentralized, and censorship-resistant social media.
            Attend the event to learn more about nostr and meet people building nostr community!"
      color="black-purple"
      background="all-black"
      imageAlt="Nostr"
      imageClass="nostr"
      clock="20:00"
      imageSrc={nostr}
      imageSmSrc={nostrsm}
      location={
        <a
          href="https://maps.app.goo.gl/totJzgSPg5y5FSpG7?g_st=ic"
          target="_blank"
          rel="noreferrer"
        >
          Zefirs
        </a>
      }
    />

    <Days
      participants
      className="hosted"
      title="September 4th"
      subtitle="Talks, demos, workshops, and user experiences presented by the BTCPay Server team"
      headTitle="BTCPayServer day "
      ticket={
        <a href="https://day.btcpayserver.org" target="_blank" rel="noreferrer">
          Proof of Merch
        </a>
      }
      color="gray"
      location={
        <a
          href="https://goo.gl/maps/3UDGjzW3BZkLik669"
          target="_blank"
          rel="noreferrer"
        >
          OraculeTang Space
        </a>
      }
      imageSrc={btcpayserverday}
      imageAlt="BTC pay server day"
      clock="12:00 - 18:00"
      infoTitle="What is a BTCPay Server day?"
      infoDescription="BTCPay Server is a self-hosted, open-source Bitcoin payment processor. Attend the event to learn more about BTCPay Server’s recent developments and meet the team in person!"
    />
  </div>
);

export default BitcoinWeek;
