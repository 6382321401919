import React from "react";
import { Link } from "react-router-dom";
import ButtonContained from "../ButtonContained/ButtonContained";
import "./styles.scss";

function BlueBanner() {
  return (
    <div className="blueBanner" id="bluebannerjs">
      <p className="blueBanner__text">
        Join 5 days of{" "}
        <span className="blueBanner__text__purple" title="pure Bitcoin-only">
          pure Bitcoin-only
        </span>{" "}
        content
      </p>
      {/* <div className="blueBanner__ticketsBox">
        <ButtonContained>
          <Link
            to="/buy-tickets"
            className="blueBanner__button__link"
            alt="Buy Tickets"
          >
            Buy Tickets
          </Link>
        </ButtonContained>
      </div> */}
    </div>
  );
}

export default BlueBanner;
