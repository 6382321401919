import React from 'react'
import bitcoinWorldConference from './bitcoin-world-conference.svg'
import bitcoinConferenceEurope from './bitcoin-conference-europe.svg'
import bitcoinEvent2023 from './bitcoin-event-2023.svg'
import joinhb from '../../assets/images/honeybadger_lightning.webp'
import './style.scss'
import ButtonContained from '../ButtonContained/ButtonContained'


function JoinCommunity() {
  return (
    <div className="joinCommunity" id="eventbannerjs">
      <h2 className='joinCommunity__title' title="Join our community">Join our community</h2>
      <div className='joinCommunity__buttons'>
        <ButtonContained>
          <a href="https://twitter.com/hodlhodl" target="_blank" className='joinCommunity__buttons__button__link' rel="noreferrer">
            <img alt='Best Bitcoin Conference in Europe' src={bitcoinConferenceEurope}/>
            Follow us
          </a>
        </ButtonContained>
        <ButtonContained>
          <a href="https://t.me/baltichoneybadger" target="_blank" className='joinCommunity__buttons__button__link' rel="noreferrer">
            <img alt='Best Bitcoin conference in the world' src={bitcoinWorldConference}/>
            Latest news
          </a>
        </ButtonContained>
        <ButtonContained>
          <a href="https://www.youtube.com/c/HodlHodl" target="_blank" className='joinCommunity__buttons__button__link' rel="noreferrer">
            <img alt='Bitcoin events in 2023' src={bitcoinEvent2023}/>
            Youtube
          </a>
        </ButtonContained>
      </div>
      <img alt='Bitcoin Conference 2023 Dates' src={joinhb} className='joinCommunity__img' />
    </div>
  )
}

export default JoinCommunity
