import React from 'react'
import { Link } from 'react-router-dom'

import email from './email.svg'
import facebook from './facebook.svg'
import './style.scss'
import { useIsMobile } from '../../hooks/useMediaQuery'

function Footer() {
    let small = useIsMobile();

    return (
        <footer className="footer">
            <div className='footer__block'>
                <p className='footer__block__item__link'><a href="https://fb.me/e/J6q1h2Yw" target="_blank" rel="noreferrer"><span className='footer__block__item__link__icon'><img src={facebook} alt="facebook" /></span>FACEBOOK</a></p>
                <p className='footer__block__item__link'><a href="mailto:info@baltichoneybadger.com"><span className='footer__block__item__link__icon'><img src={email} alt="email" /></span>info@baltichoneybadger.com</a></p>
                <p className='footer__block__item'>© Baltic Honeybadger Conference 2023</p>
            </div>
            <div className='footer__block'>
                <h2 className='footer__block__title' title="Baltic HoneyBadger 2023">Baltic HoneyBadger 2023</h2>
            </div>
            <div className='footer__block footer__block__right'>
                {small ?
                    <>
                        <div className='footer__block__right__mobile'>
                            {/* <p className='footer__block__item__link'><Link to="/buy-tickets"  alt="Buy Tickets">Buy Tickets</Link></p> */}
                            <p className='footer__block__item__link'><a href="https://goo.gl/maps/5P3o9oKUftMqwi2e6" target="_blank" rel="noreferrer">Location</a></p>
                        </div>
                        <div className='footer__block__right__mobile'>
                            <p className='footer__block__item__link'><Link to="/agenda" alt="Agenda">Agenda</Link></p>
                            <p className='footer__block__item__link'><Link to="/terms-conditions" alt="Terms">Terms and conditions</Link></p>
                        </div>
                    </> : <>
                        {/*<p className='footer__block__item__link'><Link to="/register"  alt="Register">Register</Link></p>*/}
                        {/* <p className='footer__block__item__link'><Link to="/buy-tickets" alt="Buy Tickets">Buy Tickets</Link></p> */}
                        <p className='footer__block__item__link'><Link to="/agenda" alt="Agenda">Agenda</Link></p>
                        <p className='footer__block__item__link'><a href="https://goo.gl/maps/5P3o9oKUftMqwi2e6" target="_blank" rel="noreferrer">Location</a></p>
                        <p className='footer__block__item__link'><Link to="/terms-conditions" alt="Terms">Terms and conditions</Link></p>
                    </>}

            </div>
        </footer>
    )
}

export default Footer
