import React from "react";
import { Link } from 'react-router-dom'
import { useIsMobile } from '../../hooks/useMediaQuery'
import bitcoinOnly from "./bitcoin-only.webp";
import bestBitcoinSpeakers from "./best-bitcoin-speakers.webp";
import arrowUrl from "../../assets/images/arrow.svg"
import "./style.scss";

function Plan() {
  const isMobile = useIsMobile()

  return (
    <section className="plans">
      {isMobile ? (
        <h2 className="plans__title">
          Cypherpunk movement <br /> for{" "}
          <span className="plans__title__yellow" title="passionate">passionate</span> <br />{" "}
          <span className="plans__title__yellow plans__title__mobile" title="Bitcoiners">Bitcoiners</span>
        </h2>
      ) : (
        <h2 className="plans__title">
          Cypherpunk movement for{" "}
          <span className="plans__title__yellow" title="passionate Bitcoiners">passionate Bitcoiners</span>
        </h2>
      )}

      <div className="plans__blocks">
        <div className="plans__blocks__block">
          <img
            src={bitcoinOnly}
            alt="Bitcoin-only Conference"
            className="plans__blocks__block__img"
          />
          <h3 className="plans__blocks__block__title">Bitcoin-only</h3>
          <p className="plans__blocks__block__text">
            Important, popular, and most controversial topics in Bitcoin
          </p>
          <Link to="/agenda" className="plans__blocks__block__link" alt="Agenda">
              <p className="plans__blocks__block__link__text">Conference agenda</p>
              <img src={arrowUrl} width="18" height="18" alt="Agenda" className="plans__blocks__block__link__arrow"/>
          </Link>
        </div>
        <div className="plans__blocks__block">
          <img
            src={bestBitcoinSpeakers}
            alt="Top Best Bitcoin Speakers"
            className="plans__blocks__block__img"
          />
          <h3 className="plans__blocks__block__title">Key players</h3>
          <p className="plans__blocks__block__text">
            The best networking you could get from top people in the Bitcoin industry
          </p>
          <Link to="/speakers" className="plans__blocks__block__link" alt="Speakers">
            <p className="plans__blocks__block__link__text">Conference speakers</p>
            <img src={arrowUrl} width="18" height="18" alt="Speakers" className="plans__blocks__block__link__arrow"/>
          </Link>
        </div>
      </div>
    </section>
  );
}

export default Plan;
