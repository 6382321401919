import React from 'react'
import { useIsTablet } from '../../hooks/useMediaQuery'
import rigaBitcoinWeek from './riga-bitcoin-week.webp'
import './style.scss'
import { Link } from "react-router-dom";

function RigaBitcoinWeek({ revertDirection = false }) {
  let tablet = useIsTablet();

  return (
    <div style={revertDirection ? { flexDirection: 'row-reverse' } : {}} className={"bitcoinWeek"}>
      <div className='bitcoinWeek__content'>
        <h2 className='bitcoinWeek__content__title' title="Riga bitcoin week">Riga bitcoin week</h2>
        {tablet && <img alt='Top Best Bitcoin Speakers' src={rigaBitcoinWeek} className='bitcoinWeek__content__img' />}
        <p className='bitcoinWeek__content__text'>
          Yearly initiative around the Baltic Honeybadger conference full of Bitcoin events focused on education, sharing recent development, and growing Bitcoin adoption.
        </p>
        <p className='bitcoinWeek__content__text'>
          We speak about Bitcoin, hold workshops, brainstorm, network and party! Riga Bitcoin Week is a week of high-quality educational content delivered by the top industry players.
        </p>
        <button className="bitcoinWeek__button"><Link to="/riga-bitcoin-week" className="bitcoinWeek__button__link" alt="Buy Tickets">Learn more about riga bitcoin week</Link></button>
      </div>
      {!tablet && <div className='bitcoinWeek__content'>
        <img alt='Riga' src={rigaBitcoinWeek} className='bitcoinWeek__content__img' />
      </div>
      }
    </div>
  )
}

export default RigaBitcoinWeek
