import React, { useEffect, useState } from 'react'
import { PropTypes } from "prop-types";

const createIframe = (id) => (
    <iframe
        width="100%"
        src={`https://www.youtube-nocookie.com/embed/${id}?controls=0&rel=0&autoplay=1`}
        title="Embedded youtube"
        className="swiper-video"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
    />
)

const createPlaceholder = (id, onPress) => (
    <div
        onClick={onPress}
        title="Embedded youtube"
        className="swiper-video swiper-video-placeholder"
        style={{
            backgroundImage: `url(https://i3.ytimg.com/vi/${id}/hqdefault.jpg)`,
            backgroundPosition: 'center center',
            backgroundSize: 'cover',
        }}
    />
)

const createPlayManager = () => {
    let callbacks = []

    return {
        play: (id) => {
            callbacks.forEach(callback => callback(id))
        },
        onPlay: (cb) => {
            callbacks.push(cb)
        },
        offPlay: (cb) => {
            callbacks = callbacks.filter(item => item !== cb)
        }
    }
}

const playManager = createPlayManager()

function YoutubeEmbed({ embedId }) {
    const [isActive, setIsActive] = useState(false)

    const onPress = () => {
        playManager.play(embedId)
    }

    useEffect(() => {
        const playCb = (id) => {
            setIsActive(id === embedId)
        }

        playManager.onPlay(playCb)

        return () => {
            playManager.offPlay(playCb)
        }
    }, [embedId])

    return (
        <div className="video-responsive" role="button">
            {isActive ? createIframe(embedId) : createPlaceholder(embedId, onPress)}
        </div>
    )
}

YoutubeEmbed.propTypes = {
    embedId: PropTypes.string.isRequired
};

export default YoutubeEmbed
