import React from 'react'
import { Helmet } from 'react-helmet'
import RigaBitcoinWeek from "../../components/RigaBitcoinWeek/RigaBitcoinWeek";
import RigaHotels from "../../components/RigaHotels/RigaHotels";
import JoinCommunity from "../../components/JoinCommunity/JoinCommunity";
import BlueBanner from "../../components/BlueBanner/BlueBanner";
import ConferenceLocation from "../../components/ConferenceLocation/ConferenceLocation";

import location from "./location.webp"
import location1 from "./location1.webp"
import location2 from "./location2.webp"
import location3 from "./location3.webp"

import './location.scss'

function Location() {
    return (
        <div>
            <Helmet>
                <title>Baltic Honeybadger 2023 - Conference Location</title>
                <meta name="description"
                      content="One of the biggest European Bitcoin events Baltic Honeybadger held in Riga, Latvia. "/>
            </Helmet>
            <div className="location__header">
                <h2 className="location__title"
                    title="Bringing bitcoin to riga"
                >
                    Bringing bitcoin to riga
                </h2>
            </div>
            <div className="page location">
                <p className="location__subtitle">Baltic Honeybadger Bitcoin conference takes place in Riga, Latvia</p>

                <div className="image__container">
                    <img src={location} alt="Fantadroms" className="image__container__image"/>
                    <img src={location1} alt="Fantadroms" className="image__container__image"/>
                    <img src={location2} alt="Jauna Teika" className="image__container__image"/>
                    <img src={location3} alt="Afterparty" className="image__container__image"/>
                </div>

                <ConferenceLocation/>
                <RigaBitcoinWeek revertDirection={true}/>
                <BlueBanner/>
                <RigaHotels revertDirection={true}/>
                <JoinCommunity/>
            </div>
        </div>
    )
}

export default Location
