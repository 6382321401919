import React from "react";
import "./style.scss";

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, A11y } from "swiper";
import "swiper/css";
import "swiper/css/pagination";

import exclusiveUrl from "./../../assets/images/sponsors-icons/exclusive.svg";
import soldOutUrl from "./../../assets/images//sponsors-icons/sold_out.svg";
import { useIsMobile } from '../../hooks/useMediaQuery'

function MainBlock(props) {
  let small = useIsMobile();

  return (
    <div className="sponsors-block" {...props}>
      <h2 className="sponsors-block__title">Main Sponsors</h2>
      <p className="sponsors-block__subtitle main-block">
        Main sponsors are exposed to maxim branding and
        <wbr /> recognition with extra benefits individual for each pack
      </p>
      {small ? (
        <div className="sponsor-swiper">
          <Swiper
            navigation={false}
            pagination={{ clickable: true }}
            modules={[Pagination, A11y]}
            slidesPerView={1}
            spaceBetween={30}
            grabCursor={true}
            className="mySwiper"
          >
            <SwiperSlide>
              <div className="card">
                <div className="sold_out">
                  <img src={soldOutUrl} alt="" />
                </div>
                <div className="element">
                  <div className="card__header blue">
                    <h3 className="card__title">shark</h3>
                  </div>
                  <div className="card__body">
                    <h3 className="card__money">$8 500</h3>
                    <p className="card__description">
                      1 VIP pass{" "}
                      <span className="card__descriptionAddition">
                        (access to VIP and closed events)
                      </span>
                      <br /> 2 GA passes
                    </p>
                    <p className="card__list-title">Branding included:</p>
                    <ul className="card__list">
                      <li className="card__elem">2nd stage</li>
                      <li className="card__elem">Lobby</li>
                      <li className="card__elem">Merchandise</li>
                      <li className="card__elem">Live stream</li>
                      <li className="card__elem">Website</li>
                      <li className="card__elem">
                        All Riga Bitcoin Week events
                      </li>
                    </ul>
                    <div className="card__info">
                      <span className="card__span">Booth space</span>
                      <span className="card__span">Newsletter</span>
                      <span className="card__span">No bag drop</span>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="card">
                <div className="sold_out">
                  <img src={soldOutUrl} alt="" />
                </div>
                <div className="element">
                  <div className="card__header red">
                    <h3 className="card__title">Whale</h3>
                  </div>
                  <div className="card__body">
                    <h3 className="card__money">$11 500</h3>
                    <p className="card__description">
                      3 VIP passes{" "}
                      <span className="card__descriptionAddition">
                        (access to VIP and closed events)
                      </span>
                      <br /> 2 GA passes
                    </p>
                    <p className="card__list-title">Branding included:</p>
                    <ul className="card__list">
                      <li className="card__elem">Main stage</li>
                      <li className="card__elem">Lobby</li>
                      <li className="card__elem">Merchandise</li>
                      <li className="card__elem">Live stream</li>
                      <li className="card__elem">Website</li>
                      <li className="card__elem">
                        All Riga Bitcoin Week events
                      </li>
                    </ul>
                    <div className="card__info">
                      <span className="card__span">Booth space</span>
                      <span className="card__span">Newsletter</span>
                      <span className="card__span bag">Bag drop</span>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="card">
                <div className="sold_out">
                  <img src={soldOutUrl} alt="" />
                </div>
                <div className="element">
                  <div className="card__header black exclusive">
                    <h3 className="card__title">Humpback</h3>
                    <img src={exclusiveUrl} alt="exclusiveUrl" />
                  </div>
                  <div className="card__body">
                    <h3 className="card__money">$15 000</h3>
                    <p className="card__description">
                      5 VIP passes{" "}
                      <span className="card__descriptionAddition">
                        {" "}
                        (access to VIP and closed events)
                      </span>
                      <br /> 3 GA passes
                    </p>
                    <p className="card__list-title">Branding included:</p>
                    <ul className="card__list">
                      <li className="card__elem">Food court</li>
                      <li className="card__elem">Main stage</li>
                      <li className="card__elem">Lobby</li>
                      <li className="card__elem">Merchandise</li>
                      <li className="card__elem">Live stream</li>
                      <li className="card__elem">Website</li>
                      <li className="card__elem">
                        All Riga Bitcoin Week events
                      </li>
                    </ul>
                    <div className="card__info">
                      <span className="card__span">Honeybadger Booth</span>
                      <span className="card__span">Newsletter</span>
                      <span className="card__span bag">Bag drop</span>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      ) : (
        <div className="main-cards">
          <div className="card">
            <div className="sold_out">
              <img src={soldOutUrl} alt="" />
            </div>
            <div className="element">
              <div className="card__header blue">
                <h3 className="card__title">shark</h3>
              </div>
              <div className="card__body">
                <h3 className="card__money">$8 500</h3>
                <p className="card__description">
                  1 VIP pass{" "}
                  <span className="card__descriptionAddition">
                    {" "}
                    (access to VIP and closed events)
                  </span>
                  <br /> 2 GA passes
                </p>
                <p className="card__list-title">Branding included: </p>
                <ul className="card__list">
                  <li className="card__elem">2nd stage</li>
                  <li className="card__elem">Lobby</li>
                  <li className="card__elem">Merchandise</li>
                  <li className="card__elem">Live stream</li>
                  <li className="card__elem">Website</li>
                  <li className="card__elem">All Riga Bitcoin Week events</li>
                </ul>
                <div className="card__info">
                  <span className="card__span">Booth space</span>
                  <span className="card__span">Newsletter</span>
                  <span className="card__span">No bag drop</span>
                </div>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="sold_out">
              <img src={soldOutUrl} alt="" />
            </div>
            <div className="element">
              <div className="card__header red">
                <h3 className="card__title">Whale</h3>
              </div>
              <div className="card__body">
                <h3 className="card__money">$11 500</h3>
                <p className="card__description">
                  3 VIP passes{" "}
                  <span className="card__descriptionAddition">
                    {" "}
                    (access to VIP and closed events)
                  </span>
                  <br /> 2 GA passes
                </p>
                <p className="card__list-title">Branding included:</p>
                <ul className="card__list">
                  <li className="card__elem">Main stage</li>
                  <li className="card__elem">Lobby</li>
                  <li className="card__elem">Merchandise</li>
                  <li className="card__elem">Live stream</li>
                  <li className="card__elem">Website</li>
                  <li className="card__elem">All Riga Bitcoin Week events</li>
                </ul>
                <div className="card__info">
                  <span className="card__span">Booth space</span>
                  <span className="card__span">Newsletter</span>
                  <span className="card__span bag">Bag drop</span>
                </div>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="sold_out">
              <img src={soldOutUrl} alt="" />
            </div>
            <div className="element">
              <div className="card__header black exclusive">
                <h3 className="card__title">Humpback</h3>
                <img src={exclusiveUrl} alt="exclusiveUrl" />
              </div>
              <div className="card__body">
                <h3 className="card__money">$15 000</h3>
                <p className="card__description">
                  5 VIP passes{" "}
                  <span className="card__descriptionAddition">
                    {" "}
                    (access to VIP and closed events)
                  </span>
                  <br /> 3 GA passes
                </p>
                <p className="card__list-title">Branding included:</p>
                <ul className="card__list">
                  <li className="card__elem">Food court</li>
                  <li className="card__elem">Main stage</li>
                  <li className="card__elem">Lobby</li>
                  <li className="card__elem">Merchandise</li>
                  <li className="card__elem">Live stream</li>
                  <li className="card__elem">Website</li>
                  <li className="card__elem">All Riga Bitcoin Week events</li>
                </ul>
                <div className="card__info">
                  <span className="card__span">Honeybadger Booth</span>
                  <span className="card__span">Newsletter</span>
                  <span className="card__span bag">Bag drop</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default MainBlock;
