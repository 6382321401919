import React, { useState } from 'react'
import agendafirst from '../../agendafirst.json'
import agendasecond from '../../agendasecond.json'
import { useIsMobile } from '../../hooks/useMediaQuery'

import './style.scss'

const agendaItem = (items, type, isMobile) => {
    return type === 'all' ? items.map(item => {
        return item.type === 'lunch' ? (
            <div className={`agenda__content__item ${item.type}`}>
                <h2 className={`agenda__content__item__lunch__time`} title={item.title}>{item.time}</h2>
                <h2 className={`agenda__content__item__lunch__title`} title={item.title}>{item.title}</h2>
            </div>
        ) : (
        <div className='agenda__content__item'>
            <div className={`agenda__content__item__title ${item.type}`}>
                <p className='agenda__content__item__title__time'>{item.time}</p>
                <p className='agenda__content__item__title__subtitle'>{item.subtitle}</p>
                {isMobile && 
                    <h2 className={`agenda__content__item__title__type ${item.type}`} title={item.stage}>{item.stage}</h2>
                }
            </div>
            <div className='agenda__content__item__text'>
                <p className='agenda__content__item__text__title'>{item.title}</p>
                <p className='agenda__content__item__text__author'>{item.author}</p>
            </div>
            {!isMobile && <div className='agenda__content__item__stage'>
                <h2 className={`agenda__content__item__stage__title ${item.type}`} title={item.stage}>{item.stage}</h2>
            </div> }
        </div>
    )
    }) : items.filter(item => item.type === type || item.type === 'lunch').map(item => { 
        return item.type === 'lunch' ? (
            <div className={`agenda__content__item ${item.type}`}>
                <h2 className={`agenda__content__item__lunch__time`} title={item.title}>{item.time}</h2>
                <h2 className={`agenda__content__item__lunch__title`} title={item.title}>{item.title}</h2>
            </div>
        ) : (
        <div className='agenda__content__item'>
            <div className={`agenda__content__item__title ${item.type}`}>
                <p className='agenda__content__item__title__time'>{item.time}</p>
                <p className='agenda__content__item__title__subtitle'>{item.subtitle}</p>
                {isMobile && 
                    <h2 className={`agenda__content__item__title__type ${item.type}`} title={item.stage}>{item.stage}</h2>
                }
            </div>
            <div className='agenda__content__item__text'>
                <p className='agenda__content__item__text__title'>{item.title}</p>
                <p className='agenda__content__item__text__author'>{item.author}</p>
            </div>
            {!isMobile && <div className='agenda__content__item__stage'>
                <h2 className={`agenda__content__item__stage__title ${item.type}`} title={item.stage}>{item.stage}</h2>
            </div>}
        </div>
    )})
}


function Agenda() {
    const [day, setDay] = useState('first')
    const [type, setType] = useState('all')
    const isMobile = useIsMobile()

    const agendaItems = day === 'first' ? agendaItem(agendafirst, type, isMobile) : agendaItem(agendasecond, type, isMobile)

    return (
        <section className="agenda">
            <h1 className="agenda__title" title="Baltic Honeybadger agenda 2023">Baltic Honeybadger agenda 2023</h1>
            <p className="agenda__subtitle">The conference is set with 3 stages, including the workshop space. <br />
                        MC’s: Stephan Livera, Peter McCormack, Joe Nakamoto, Daniel Prince, Lunaticoin.</p>

            <div className='agenda__types'>
                <div className='agenda__types__day'>
                    <span className={`agenda__types__day__item ${day === 'first' && 'active'}`} onClick={() => setDay('first')}>Day 1</span>
                    <span className={`agenda__types__day__item ${day === 'second' && 'active'}`} onClick={() => setDay('second')}>Day 2</span>
                </div>
                <div className='agenda__types__type'>
                    <span className={`agenda__types__type__item ${type === 'all' && 'active'}`} onClick={() => setType('all')}>All stages</span>
                    <span className={`agenda__types__type__item ${type === 'main' && 'active'}`} onClick={() => setType('main')}>Main stage</span>
                    <span className={`agenda__types__type__item ${type === 'cypherpunk' && 'active'}`} onClick={() => setType('cypherpunk')}>Cypherpunk stage</span>
                    <span className={`agenda__types__type__item ${type === 'pow' && 'active'}`} onClick={() => setType('pow')}>PoW stage</span>
                </div>
            </div>

            <div className='agenda__content'>
                {agendaItems}
            </div>
        </section>
    )
}

export default Agenda
