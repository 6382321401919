import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';

import speakers from '../../speakers.json';
import arrowUpUrl from './../../assets/images/arrow-up.svg';

import './style.scss'
import gsap from 'gsap'
import { ScrollTrigger } from "../../assets/js/ScrollTrigger";

const IMAGE_URI = '/images/speakers/'

const SpeakersList = React.memo(() => {
    let path = window.location.pathname;
    let content = null;
    const isIndexPage = path === '/'

    const screenWidth = window.screen.width;
    let tablet = screenWidth <= 1200;
    let mobile = screenWidth < 768;

    const mapSpeaker = (speaker) => {
        const url = IMAGE_URI + speaker.image;
        return (
            <figure key={speaker.id} id={speaker.id} className="person">
                <a href={speaker.twitUrl} className="person__link" target="_blank" rel="noreferrer">
                    <img src={url} alt={speaker.image} className="person__image"/>
                    {speaker.remotely && <div className='person__remotely'>Remotely</div>}
                </a>
                <figcaption>
                    <h2 className="block__title">{speaker.name}</h2>
                    <p className="block__text">{speaker.title}</p>
                </figcaption>
            </figure>
        )
    }

    if (isIndexPage) {
        if(tablet && !mobile) {
            content = speakers.filter(item => item.main === true).filter(item => item.id <= 6).map(speaker => mapSpeaker(speaker))
        } else if (mobile) {
            content = speakers.filter(item => item.main === true).filter(item => item.id <= 4).map(speaker => mapSpeaker(speaker))
        } else {
            content = speakers.filter(item => item.main === true).map(speaker => mapSpeaker(speaker))
        }
    } else {
        content = speakers.map(speaker => mapSpeaker(speaker))
    }

    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);

        gsap.fromTo("#persons", { autoAlpha: 0, },
        { duration: .6, autoAlpha: 1,
            scrollTrigger: {
                trigger: "#plans",
                start: "top, +=200",
                end: "+=200",
                toggleActions: 'play none none reverse'
            }
        });
    }, []);

    return (
        <section className="persons" id="persons">
            {isIndexPage && 
                <h2 className="persons__index__title"><span className="persons__index__title__yellow">high-profile speakers</span> from all around the world</h2>
            }
            <div className="persons__wrap">
                {content}
            </div>
            {isIndexPage &&
                    <Link to="/speakers" className="persons__wrapper">
                        <p className="persons__link"> All CONFERENCE speakers </p>
                        <img src={arrowUpUrl} alt="Speakers" className="persons__image"/>
                    </Link>
                }
        </section>
    )
})

export default SpeakersList

